import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;
const PrivacyPolicy = () => {


  const { loading, error, data } = useQuery(PAGE_QUERY, {
      variables: {slug: 'kredyt-samochodowy'}
  });

  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Kredyty samochodowe dla firm i osób fizycznych'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

            <h1>Kredyty samochodowe Dla firm i osób fizycznych</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

          </HandleLoading>
          {/* <div className={s_text.double}>
            <div>
              <p>
                Cieszący się ogromną popularnością leasing nie jest oczywiście jedyną możliwością rozpoczęcia
                użytkowania wymarzonego auta. W wielu sytuacjach korzystniejszą opcją okazuje się kredyt samochodowy.
              </p>
              <p>
                <strong>Kredyt</strong> to doskonałe rozwiązanie dla tych, którzy lubią mieć i czuć. Lubisz mieć coś co należy
                tylko i wyłącznie do Ciebie i lubisz czuć, że jesteś jedynym właścicielem tej jednej jedynej wymarzonej
                rzeczy? Tak…To dla takich ludzi jak Ty mamy <strong>produkty kredytowe</strong>. Szeroki wachlarz ofert na oświadczenie,
                bez zbędnych formalności. Tylko Ty i My. Bo Ty dyktujesz warunki, a My spełniamy Twoje marzenia. Wygodny
                system ratalny, jasny i przejrzysty harmonogram spłaty raty, bez zbędnych prowizji i ukrytych kosztów.
                Wszystko czarno na białym. Nasza specjalność, to <strong>kredyty konsumpcyjne, na działalność bądź samochód</strong>.
                Jeśli, któryś z <strong>produktów kredytowych</strong> Cię zainspirował, śmiało nie czekaj, przyjdź do Nas i spełnij swoje pragnienia.
              </p>
            </div>
            <div>
              <p>
                Swoją ofertę kierujemy zarówno do osób prywatnych, jak i firm, które zastanawiają się nad zakupem nowego lub używanego
                pojazdu. Chcemy, aby każdy z Klientów mógł jak najszybciej otrzymać swoje wymarzone i niezbędne do realizacji
                codziennych obowiązków auto, dlatego też gwarantujemy atrakcyjne oferty, dopasowane do ich indywidualnych
                potrzeb oraz minimum formalności, dzięki czemu cały proces kredytowy przebiega szybko i bezproblemowo.
              </p>
            </div>
          </div>


          <div className={s_text.double}>
            <div>
              <h2>Dlaczego warto wybrać kredyt samochodowy?</h2>
              <ul className={s_text.checkmark_list}>
                <li>Szybka decyzja kredytowa, nawet w 3 godziny!</li>
                <li>Minimum formalności, uproszczone procedury</li>
                <li>Atrakcyjny pakiet ubezpieczeń</li>
              </ul>
            </div>
            <div>
              <h2>Warunki kredytu na samochód</h2>
              <ul className={s_text.dash_list}>
                <li>Opłata wstępna od 0%</li>
                <li>Oprocentowanie od 4,99%</li>
                <li>Elastyczny i dogodny czas finansowania (od 3 miesięcy do 12 lat)</li>
                <li>Brak wymaganych zaświadczeń ZUS/US</li>
                <li>Na oświadczenie</li>
                <li>Kredyt na dowolny pojazd w wieku do 15 lat</li>
              </ul>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
